import React, { useState, useEffect } from "react";
import { useAuthSession } from "../../hooks/useAuthSession";
import { fetchProjects } from "../../store/fetchDB";
import { addProjectMember, createChat } from "../../store/writeDB";
import { supabase } from "../../auth/supabaseClient";

import AddIcon from "@mui/icons-material/Add";
import { Grid, Typography, Button, Box, IconButton } from "@mui/material";
import ProjectGrid from "./ProjectGrid";
import CreateProjectModal from "../CreateProjectModal";
import useCreateGridItem from "../../hooks/useCreateGridItem";
import { useNavigate, useParams } from "react-router-dom";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import InviteUsersModal, {
  fetchProjectMembers,
} from "../groups/InviteUsersModal";
import { updateChatData } from "../../hooks/useQueryData";

export default function SpaceBase({}) {
  const { user } = useAuthSession();
  const [recentChats, setRecentChats] = useState([]);
  const navigate = useNavigate();
  const [projectTitle, setProjectTitle] = useState("");
  const { projectId } = useParams();

  const [isInviteModalOpen, setInviteModalOpen] = useState(false);

  const handleInviteModalOpen = () => {
    setInviteModalOpen(true);
  };

  const handleFinishCreateChat = async (chatTitle) => {
    // Your logic for creating a new project goes here
    const { data, error } = await createChat(user.id, projectId, chatTitle);
    if (error) {
      console.error("Error creating project:", error);
    } else {
      console.log("Project created:", data);
      // setProjects([...projects, data]);
      fetchRecentChats(user.id).then(({ data: rcData, error: rcError }) => {
        if (rcError) {
          console.error("Error fetching recent chats:", rcError);
        } else {
          // find project name matching project id:
          console.log("Recent chats:", rcData);
          const nextChat = rcData.find(
            (chat) => chat.context.name === chatTitle
          );
          navigate(`/p/${projectId}/${nextChat.id}`, { state: { nextChat } });
        }
      });
    }
  };

  const {
    openNameModal,
    setOpenNameModal,
    dialogueText,
    dialogueFct,
    handleCreateItem,
  } = useCreateGridItem(handleFinishCreateChat);

  useEffect(() => {
    fetchProjects(user.id).then(({ data, error }) => {
      if (error) {
        console.error("Error fetching projects:", error);
      } else {
        console.log("Projects:", data);

        // find project matching projectId
        const project = data.find((project) => project.id === projectId);
        if (project) {
          setProjectTitle(project.name);
        }
      }
    });
  }, [user.id, projectId]);

  // Fetch recent chats on mount
  useEffect(() => {
    if (user.id) {
      fetchRecentChats(user.id);
    }
  }, [user.id]);

  async function fetchRecentChats(userId, maxChats = 15) {
    const { data, error } = await supabase
      .from("chats")
      .select("*, project:project_id (name)")
      .eq("project_id", projectId)
      // .in("id", (query) =>
      //   query
      //     .from("chat_participants")
      //     .select("chat_id")
      //     .eq("user_id", userId)
      // )
      .order("created_at", { ascending: false })
      .limit(maxChats);

    if (error) {
      console.error("Error fetching recent chats:", error);
    } else {
      const chats = data.map((chat) => {
        return { ...chat, name: chat.context.name };
      });
      updateChatData(data);
      setProjectTitle(chats[0].project.name);
      setRecentChats(chats);
      console.log("Recent chats:", data);
    }
    return { data, error };
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: (theme) => theme.spacing(2),
        }}
      >
        <Typography variant="h4" component="h1" gutterBottom>
          {projectTitle}
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            gap: 2,
          }}
        >
          {/* add button for inviting new users */}
          <IconButton
            variant="contained"
            color="secondary"
            size="large"
            // startIcon={<AddIcon />}
            onClick={handleInviteModalOpen}
            sx={{
              marginTop: (theme) => theme.spacing(2),
              marginBottom: (theme) => theme.spacing(2),
            }}
          >
            <PersonAddAlt1Icon />
            {/* Invite New User */}
          </IconButton>
          <Button
            variant="contained"
            color="primary"
            size="large"
            startIcon={<AddIcon />}
            onClick={() => {
              handleCreateItem("New Chat in Space");
            }}
            sx={{
              marginTop: (theme) => theme.spacing(2),
              marginBottom: (theme) => theme.spacing(2),
            }}
          >
            Start New Chat
          </Button>
        </Box>
      </Box>
      <ProjectGrid
        items={recentChats}
        buttonText="Open Messages"
        onButtonClick={(chat) => navigate(`/p/${projectId}/${chat.id}`)}
      />
      <CreateProjectModal
        openState={{ open: openNameModal, setOpen: setOpenNameModal }}
        dialogueText={dialogueText}
        onCreate={dialogueFct.fct}
      />
      <InviteUsersModal
        isOpen={isInviteModalOpen}
        handleClose={() => setInviteModalOpen(false)}
        identifierId={projectId}
        fetchFunction={fetchProjectMembers}
        onSubmit={(newParticipants) => {
          console.log("New project Participants:", newParticipants);
          newParticipants.forEach((participant) => {
            console.log("Add participant:", participant);
            addProjectMember(projectId, participant.id);
            // addChatParticipant(chatId, participant.id);
          });
          setInviteModalOpen(false);
        }}
      />
    </Box>
  );
}
