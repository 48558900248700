// import CodeMirror from '@uiw/react-codemirror';
// import { javascript } from '@codemirror/lang-javascript';
// import { okaidia } from '@uiw/codemirror-theme-okaidia';
// import '@codemirror/lib/codemirror.css';
// import '@uiw/codemirror-theme-okaidia/codemirror-okaidia.css';
import { Box } from "@mui/material";
import Highlight from "react-highlight";
import "highlight.js/styles/atom-one-dark-reasonable.css";

import rehypeRaw from "rehype-raw";
import remarkGfm from "remark-gfm";

// import {
//   // BrowserView, MobileView, isBrowser,
//   isMobile,
// } from "react-device-detect";
import ReactMarkdown from "react-markdown";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { oneDark as chatStyle } from "react-syntax-highlighter/dist/esm/styles/prism";
// import { monokaiSublime as chatStyle } from 'react-syntax-highlighter/dist/esm/styles/hljs'
import CopyBtn from "./CopyBtn";
// import useAuthSession from "../hooks/useAuthSession";
// import { Editor, EditorProvider, HtmlEditor } from "@aeaton/react-prosemirror";
// import {
//   plugins,
//   schema
// } from "@aeaton/react-prosemirror-config-default";
// import hljs from "highlight.js/lib/core";
// import { highlightPlugin } from "prosemirror-highlightjs";

const extractCodeBlocks = (messageContent) => {
  const codeBlockRegex = /```(\w*)([\s\S]*?)```/g;
  // const codeBlockRegex = /```(\w*)\n?([\s\S]*?)```/g
  // const codeBlockRegex = /```(\w*)([\s\S]*?(?:(?!``)[\s\S]))*?/g
  // const codeBlockRegex = /(\w*)([\s\S]*?(?:(?!```)[\s\S]))*?```/g

  const codeBlocks = [];

  let match;
  while ((match = codeBlockRegex.exec(messageContent)) !== null) {
    const language = match[1] || "javascript";
    const code = match[2];

    messageContent = messageContent.replace(match[0], `{{}}`);
    codeBlocks.push({ language, code: code.trim() });
  }

  return {
    codeBlocks,
    messageContent,
  };
};

const TextBlockHTML = ({ text }) => {
  // convert text with newlines to <span> + <br/>
  // regex match the \n and replace with <br/>:

  return text;

  // .replace(/\n/g, '<br>')
};

const ChatElementBlocks = ({
  isUserMessage,
  message,
  messageContent,
  codeBlocks,
}) => {
  // const { user } = useAuthSession();
  let elements = [];
  // messageContent.split('{{}}').map((text, index) => {
  //   // text = text.replace(/\n/g, '<br>')
  //   //
  //   // console.log("Code block length:", codeBlocks.length)
  //   // console.log("index ", index)
  //   // console.log("codeBlocks[index]", codeBlocks[index])
  //   if (!codeBlocks[index]) {
  //     elements.push(
  //       <span className={'text-span'} key={index}>
  //         {text}
  //       </span>
  //     )
  //     return
  //   }

  //   const { language, code } = codeBlocks[index]

  //   elements = [
  //     ...elements,
  //     <span className={'text-span'} key={index}>
  //       {text}
  //     </span>,
  //     <Highlight className={`${language} word-break-all`}>{code}</Highlight>,
  //     //   <EditorProvider doc={doc} plugins={[
  //     //     ...plugins,
  //     //     highlightPlugin(hljs),
  //     //     readOnlyPlugin]}>
  //     //   <Editor readOnly/>
  //     // </EditorProvider>,

  //     //   <CodeMirror
  //     //   key={index}
  //     //   value={code}
  //     //   options={{
  //     //     mode: language,
  //     //     theme: okaidia,
  //     //     readOnly: true,
  //     //   }}
  //     //   height="auto"
  //     // />,
  //     <br key={index + 'br'} />
  //   ]
  // })
  // Add the CodeCopyBtn component to our PRE element
  const Pre = ({ children }) => (
    <div className="pre-container">
      <pre className="copy-pre" style={{}}>
        <CopyBtn sx={{ m: 1 }}>{children}</CopyBtn>
        {children}
      </pre>
    </div>
  );

  return (
    <Box
      sx={{
        flexGrow: 1,
        color: isUserMessage ? "#ffffff" : "#000000",
        backgroundColor: isUserMessage ? "#0b93f6" : "#e5e5ea",
        borderRadius: "20px",
        padding: "7px",
      }}
    >
      {/* {elements} */}
      <ReactMarkdown
        children={message.content}
        className="pre-wrap"
        linkTarget="_blank"
        rehypePlugins={[rehypeRaw]}
        remarkPlugins={[remarkGfm]}
        components={{
          pre: Pre,
          code({ node, inline, className, children, ...props }) {
            // console.log('Classname:', className)

            const match = /language-(\w+)/.exec(className || "");
            // return !inline && match ? (
            if (!match) {
              // console.log('No match', node, inline, className, children, props)
              // if the inline doesn't have any newlines, just return span, otherwise wrap in code
              if (children[0].includes("\n")) {
                return (
                  <div style={{ "white-space": "normal", padding: "10px" }}>
                    <code
                      className={`generic-code language word-break-all`}
                      style={{ "white-space": "pre-wrap", overflow: "hidden" }}
                      {...props}
                    >
                      <span>{children}</span>
                    </code>
                  </div>
                );
              } else {
                return (
                  <span className="markdown-text" {...props}>
                    {children}
                  </span>
                );
              }
              // return (
              //   <div style={{ "white-space": "normal", padding: "10px" }}>
              //     <code
              //       className={`generic-code language word-break-all`}
              //       style={
              //         {
              //           // margin: '8px'
              //           // padding: '58px'
              //           // padding: '8px'
              //         }
              //       }
              //       {...props}
              //     >
              //       <span>{children}</span>
              //     </code>
              //   </div>
              // );
            }

            return (
              <SyntaxHighlighter
                children={String(children).replace(/\n$/, "")}
                style={{
                  ...chatStyle,
                  "white-space": "pre-wrap",
                  overflow: "hidden",
                }}
                PreTag="div"
                codeTagProps={{
                  className: `howdy word-break-all`,
                  style: {
                    whiteSpace: "pre-wrap",
                    "white-space": "pre-wrap",
                  },
                }}
                // className='word-break-all'
                language={match ? match[1] : "javascript"}
                // PreTag='div'
              />
            );
            // )
            // : (
            //   <code className={`word-break-all`} {...props}>
            //     {children}
            //   </code>
            // )
          },
        }}
      />
    </Box>
  );
};
const ChatMessage = ({ message, userId }) => {
  const isUserMessage =
    userId == message.userId && !(message.role === "assistant");
  const { codeBlocks, messageContent } = extractCodeBlocks(message.content);
  return (
    // <Box
    //   sx={{
    //     color: isUserMessage ? "#ffffff" : "#000000",
    //     backgroundColor: isUserMessage ? "#0b93f6" : "#e5e5ea",
    //     borderRadius: "20px",
    //     padding: "10px",
    //   }}
    // >
    <ChatElementBlocks
      message={message}
      isUserMessage={isUserMessage}
      messageContent={messageContent}
      codeBlocks={codeBlocks}
    />
    //   {/* {codeBlocks.map(({ language, code }, index) => (
    //     <CodeMirror
    //       key={index}
    //       value={code}
    //       options={{
    //         mode: language,
    //         theme: okaidia,
    //         readOnly: true,
    //       }}
    //       height="auto"
    //     />
    //   ))} */}
    // </Box>
  );
};

export default ChatMessage;

// import { Schema } from 'prosemirror-model'
// import { createHTMLTransformer } from '@aeaton/prosemirror-transformers'
// import {Box } from "@mui/material";
// import hljs from "highlight.js/lib/core";
// import { Editor, EditorProvider, HtmlEditor } from "@aeaton/react-prosemirror";
// import {
//   plugins,
//   schema
// } from "@aeaton/react-prosemirror-config-default";
// import Prism from './prism-imports';
// import { Plugin } from 'prosemirror-state';
// import 'prismjs/themes/prism.css'; // or any other theme available
// import 'prismjs/themes/prism-tomorrow.css'; // Import the theme
// import { highlightPlugin } from "prosemirror-highlightjs";

// const readOnlyPlugin = new Plugin({
//   props: {
//     editable: () => false,
//   },
// });

// const oschema = new Schema({
//   nodes: {
//     text: { group: "inline" },
//     doc: { content: "block+" },
//     paragraph: {
//       content: "inline*",
//       group: "block",
//       parseDOM: [{ tag: "p" }],
//       toDOM: () => ["p", 0],
//     },
//     code_block: {
//       content: "text*",
//       marks: "",
//       group: "block",
//       code: true,
//       defining: true,
//       parseDOM: [{ tag: "pre", preserveWhitespace: "full" }],
//       toDOM: () => ["pre", ["code", 0]],
//     },
//   },
//   marks: {
//     strong: {
//       parseDOM: [{ tag: "strong" }],
//       toDOM: () => ["strong", 0],
//     },
//     emphasis: {
//       parseDOM: [{ tag: "em" }],
//       toDOM: () => ["em", 0],
//     },
//     code: {
//       parseDOM: [{ tag: "code" }],
//       toDOM: () => ["code", 0],
//     },
//   },
// });

// const transformer = createHTMLTransformer(schema)
// const applySyntaxHighlighting = (messageContent) => {
//   const codeBlockRegex = /```(\w+)\s+([\s\S]*?)```/g;
//   let content = messageContent;

//   let match;
//   while ((match = codeBlockRegex.exec(messageContent)) !== null) {
//     const language = match[1];
//     const code = match[2];
//     const highlightedCode = Prism.highlight(
//       code,
//       Prism.languages[language] || Prism.languages.javascript,
//       language
//     );
//     content = content.replace(match[0], highlightedCode);
//   }

//   return `${content}`;
// };

// const highlightCode = (language, code) => {
//   let plang = Prism.languages[language];
//   if(!plang) {    //default to javascript
//     plang = Prism.languages.javascript;
//     language = 'javascript';
//   }

//   // if (Prism.languages[language]) {

//     return Prism.highlight(code, plang, language);
//   // }
//   // return code;
// };

// const ChatMessage = ({ message }) => {
//   const isUserMessage = !(message.role === "assistant");
//     const highlightedContent = applySyntaxHighlighting(message.content);

//     const messageContent = message.content;
//     const codeBlockRegex = /```(\w*)([\s\S]*?)```/g;
//     const parsedContent = messageContent.replace(codeBlockRegex, (_, lang, code) => {
//       const language = lang || "javascript";
//       const highlightedCode = highlightCode(language, code);
//       console.log("Parse replace:", language, code, highlightedCode)
//       return `<pre><code class="language-${language}">${highlightedCode}</code></pre>`;
//       // return `${highlightedCode}`;
//     }).replace(/\n/g, '<br>');

//     console.log('parsedContent:', parsedContent)
//     const doc = transformer.parse(parsedContent);
//     // const doc = transformer.parse(message.content);
//     // const doc = transformer.parse(highlightedContent)

//     // console.log('highlightedContent:', highlightedContent)
//     console.log('doc:', doc)
//   return (
//     <Box
//       sx={{
//         color: isUserMessage ? "#ffffff" : "#000000",
//         backgroundColor: isUserMessage ? "#0b93f6" : "#e5e5ea",
//         borderRadius: "20px",
//         padding: "10px",
//       }}
//     >
//      <EditorProvider doc={doc} plugins={[ ...plugins, highlightPlugin(hljs), readOnlyPlugin]}>
//       <Editor readOnly/>
//     </EditorProvider>
//     </Box>
//   );
// };

// export default ChatMessage;
