// InviteUsersModal.js

import React, { useState, useEffect } from "react";
import { supabase } from "../../auth/supabaseClient";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import AvatarGroup from "@mui/material/AvatarGroup";
import Avatar from "@mui/material/Avatar";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import useAuthSession from "../../hooks/useAuthSession";
import { updateUserInfoData } from "../../hooks/useQueryData";

export const fetchChatParticipants = async (chatId, setParticipants) => {
  // const { data, error } = await supabase
  //   .from("chat_participants")
  //   .select("user_id:user_id, users_view: username, avatar_url")
  //   .eq("chat_id", chatId);
  console.log("FETCHING CHART PARITICPANTS");
  const { data, error } = await supabase
    .from("chat_participants")
    .select(
      `
        user_id,
        users_view:users_view (id, username, avatar_url)
    `
    )
    .eq("chat_id", chatId);

  console.log("chat participants", data);

  if (data) {
    const users = data.map((item) => item.users_view);
    setParticipants(users);
    updateUserInfoData(users);
  }
  if (error) {
    console.error(error);
  }
};

export const fetchProjectMembers = async (projectId, setParticipants) => {
  console.log("FETCHING PROJECTS PARITICPANTS");
  const { data, error } = await supabase
    .from("project_members")
    .select("user_id, users_view:users_view (id, username, avatar_url)")
    .eq("project_id", projectId);
  console.log("Project memebers:", data);
  if (data) {
    const users = data.map((item) => item.users_view);
    setParticipants(users);
    updateUserInfoData(users);
  }
  if (error) {
    console.error(error);
  }
};
export const searchUsernames = async (selfId, searchText, setSearchResults) => {
  const { data, error } = await supabase
    .from("users_view")
    .select("id, username, avatar_url")
    .ilike("username", `%${searchText}%`)
    .limit(10);
  if (data) {
    console.log("user search data", data);
    // remove self user id from this
    const nonUserData = data.filter((item) => item.id !== selfId);
    setSearchResults(nonUserData);
    updateUserInfoData(data);
  }
  if (error) {
    console.error("user search error", error);
  }
};

export const InviteUsersModal = ({
  isOpen,
  handleClose,
  identifierId,
  fetchFunction,
  onSubmit,
}) => {
  const { user } = useAuthSession();
  const [participants, setParticipants] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    if (identifierId) {
      fetchFunction(identifierId, setParticipants);
    }
  }, [identifierId]);

  const handleSearchChange = async (searchText) => {
    console.log("searchText", searchText);
    searchUsernames(user.id, searchText, setSearchResults);
  };

  const handleSubmit = async () => {
    // Logic for adding selected users to chat_participants table
    await onSubmit(selectedUsers);
    // // Close modal once users are added
    // handleClose();
  };
  // console.log("Existing participants", participants);

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      PaperProps={{
        style: {
          minHeight: "70%", // Adjust this value to set the desired height
        },
      }}
      fullWidth
    >
      <DialogTitle>
        Invite Users
        <IconButton
          edge="end"
          color="inherit"
          onClick={handleClose}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start",
          gap: 2,
        }}
      >
        <AvatarGroup max={4}>
          {participants.map((user) => {
            // console.log("User:", user);
            return (
              <Avatar key={user.id} alt={user.username} src={user.avatar_url}>
                {user.username[0].toUpperCase()}
              </Avatar>
            );
          })}
        </AvatarGroup>
        <Autocomplete
          multiple
          sx={{ width: "100%" }}
          id="user-search"
          options={searchResults}
          getOptionLabel={(option) => option.username}
          onChange={(event, newValue) => setSelectedUsers(newValue)}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Search Users"
              variant="outlined"
              onChange={(e) => handleSearchChange(e.target.value)}
            />
          )}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSubmit}>Invite</Button>
      </DialogActions>
    </Dialog>
  );
};

export default InviteUsersModal;
