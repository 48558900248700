import { supabase } from "../auth/supabaseClient";
import { updateChatData, updateProjectData } from "../hooks/useQueryData";

export async function fetchMessages(projectId, chatId, maxMessages = null) {
  if (!projectId || !chatId) {
    console.error("Both projectId and chatId are required.");
    return;
  }

  let query = supabase
    .from("messages")
    .select(`*, user:user_id (id, username, avatar_url)`)
    .eq("chat_id", chatId)
    .order("created_at", { ascending: false });

  if (maxMessages !== null) {
    query = query.limit(maxMessages);
  }

  return await query;
}

export async function fetchProjects(userId) {
  const { data, error } = await supabase.from("user_projects").select("*");
  //   .eq("owner_id", userId);
  if (error) return { error };
  updateProjectData(data);
  return { data };
}

export async function fetchChats(projectId) {
  const { data, error } = await supabase
    .from("chats")
    .select()
    .eq("project_id", projectId);
  if (error) return { error };
  updateChatData(data);
  return { data };
}
