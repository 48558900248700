import React from "react";
import Box from "@mui/material/Box";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

export default function CodeCopyBtn({ children, sx }) {
  const [copyOk, setCopyOk] = React.useState(false);

  const iconColor = copyOk ? "#1b5e20" : "#ddd";
  const icon = copyOk ? "fa-check-square back-white" : "fa-copy";

  const handleClick = (e) => {
    navigator.clipboard.writeText(children[0].props.children[0]);
    console.log(children);

    setCopyOk(true);
    setTimeout(() => {
      setCopyOk(false);
    }, 500);
  };
  const fsize = "0.9rem";
  return (
    <Box sx={{ ...sx }} className="code-copy-btn">
      {copyOk ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "row",
            fontSize: fsize,
            fontFamily: "monospace",
          }}
        >
          <CheckCircleIcon fontSize={fsize} style={{ color: iconColor }} />{" "}
          <Box sx={{ m: 1 }}>copied!</Box>
        </Box>
      ) : (
        <ContentCopyIcon onClick={handleClick} style={{ color: iconColor }} />
      )}
      {/* <i
        className={`fas ${icon}`}
        onClick={handleClick}
        style={{ color: iconColor }}
      /> */}
    </Box>
  );
}
