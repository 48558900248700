import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
// import Div100vh from 'react-div-100vh'

import 'react-quill/dist/quill.snow.css' // Import the styles
import 'prismjs/themes/prism.css' // or any other theme available
import 'prismjs/themes/prism-tomorrow.css' // Import the theme

// Add other languages as needed

import App from './App'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from 'react-router-dom'
const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <React.StrictMode>
    <BrowserRouter>
      {/* <Div100vh> */}
      <App />
      {/* </Div100vh> */}
    </BrowserRouter>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
