import React from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  MenuItem,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import { signOut } from "../auth";
import { useNavigate } from "react-router-dom";
import AppBarBreadcrumbs from "./projects/AppBarBreadcrumbs";
export const AppHeader = ({ open, handleDrawerOpen }) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogout = async () => {
    // Implement your logout logic here
    await signOut();
    console.log("Logged out");
  };

  return (
    <AppBar
      position="static"
      sx={
        {
          // width: `calc(100% - ${open ? 240 : 0}px)`,
          // height: "64px",
          // display: "flex",
          // alignItems: "center",
          // justifyContent: "center",
        }
      }
    >
      <Toolbar
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={handleDrawerOpen}
          sx={{
            // height: "100px",
            // vertically center

            mr: 1,
            ...(open && { display: "none" }),
          }}
        >
          <MenuIcon />
        </IconButton>
        <AppBarBreadcrumbs />
        {/* <Typography
          variant="h6"
          component="div"
          onClick={() => {
            navigate("/");
          }}
          sx={{
            flexGrow: 1,
            cursor: "pointer",
          }}
        >
          Three's Company
        </Typography> */}
        <div>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
          >
            <AccountCircle />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={handleClose}>Profile</MenuItem>
            <MenuItem onClick={handleClose}>My account</MenuItem>
            <MenuItem onClick={handleLogout}>Sign out</MenuItem>
          </Menu>
        </div>
      </Toolbar>
    </AppBar>
  );
};
export default AppHeader;
