import * as React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import { isMobile } from "react-device-detect";
import { useParams, useNavigate } from "react-router-dom";
import { useChatData, useProjectData } from "../../hooks/useQueryData";
function handleClick(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
  event.preventDefault();
  console.info("You clicked a breadcrumb.");
}

const BreadcrumbLink = ({ navigate, link, text }) => {
  return (
    <Typography
      variant={isMobile ? "h8" : "h6"}
      component="div"
      onClick={() => {
        navigate(link);
      }}
      sx={{
        // flexGrow: 1,
        cursor: "pointer",
      }}
    >
      {text}
    </Typography>
  );
};

export default function AppBarBreadcrumbs() {
  const navigate = useNavigate();
  const { projectId, chatId } = useParams();

  const chatData = useChatData();
  const projectData = useProjectData();

  const links = [];

  links.push(
    <BreadcrumbLink
      key={links.length}
      navigate={navigate}
      link="/"
      text="Spaces"
    />
  );

  if (projectId) {
    links.push(
      <BreadcrumbLink
        key={links.length}
        navigate={navigate}
        link={`/p/${projectId}`}
        text={projectData[projectId] ? projectData[projectId].name : "Chats"}
      />
    );
  }

  if (chatId && chatData[chatId]) {
    const thisChat = chatData[chatId];
    // console.log("chatData", thisChat);

    links.push(
      <BreadcrumbLink
        key={links.length}
        navigate={navigate}
        link={`/p/${projectId}/${chatId}`}
        text={thisChat.context ? thisChat.context.name : thisChat.name}
      />
    );
  }

  return (
    <Box
      role="presentation"
      sx={{
        flexGrow: 1,
      }}
      onClick={handleClick}
    >
      <Breadcrumbs aria-label="breadcrumb" sx={{ color: "inherit" }}>
        {links}
      </Breadcrumbs>
    </Box>
  );
}
