// useSession.js
import { useSnapshot } from "valtio";
import { sessionState } from "../auth";
import { supabase } from "../auth/supabaseClient";

export const useChatRealtime = ({ chatId, userId }) => {
  // check if chatId is in sessionState.session.subscriptions
  // if not, add it
  // if so, return it
  console.log("Session state;", sessionState);
  let subscription = sessionState.session.subscriptions[chatId];
  if (!subscription) {
    const subdata = {
      callbacks: [],
      presenceCallbacks: [],
      subscription: undefined,
      functions: {},
    };
    subdata.channel = supabase
      .channel(chatId, {
        config: {
          presence: {
            key: userId,
          },
        },
      })
      .on("presence", { event: "sync" }, () => {
        console.log("Chat channel state: ", subdata.channel.presenceState());
        subdata.presenceCallbacks.forEach((cb) =>
          cb.fct(subdata.channel.presenceState())
        );
      })
      .on(
        "postgres_changes",
        {
          event: "INSERT",
          schema: "public",
          table: "messages",
          filter: `chat_id=eq.${chatId}`,
        },
        (payload) => {
          console.log("Messages change received!", payload);
          subdata.callbacks.forEach((cb) => cb.fct(payload));
        }
      );
    subdata.subscription = subdata.channel.subscribe();

    subdata.functions.changeState = async (state) => {
      await subdata.channel.track(state);
    };
    // create a function for adding a callback
    subdata.functions.addPresenceCallback = (name, fct) => {
      subdata.functions.removePresenceCallback(name);
      if (fct != null) {
        subdata.presenceCallbacks.push({
          name,
          fct,
        });
      }
    };
    // remove a callback
    subdata.functions.removePresenceCallback = (name) => {
      subdata.presenceCallbacks = subdata.presenceCallbacks.filter(
        (cb) => cb.name !== name
      );
    };
    // create a function for adding a callback
    subdata.functions.addCallback = (name, fct) => {
      subdata.functions.removeCallback(name);
      if (fct != null) {
        subdata.callbacks.push({
          name,
          fct,
        });
      }
    };
    // remove a callback
    subdata.functions.removeCallback = (name) => {
      subdata.callbacks = subdata.callbacks.filter((cb) => cb.name !== name);
    };

    sessionState.session.subscriptions[chatId] = subdata;
  }
  return useSnapshot(sessionState.session.subscriptions[chatId].functions);
};

export default useChatRealtime;
