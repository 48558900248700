import React, { useState } from "react";

// import CircularProgress from '@mui/material/CircularProgress';

import Chat from './components/Chat';
import LoginPage from './components/LoginPage';
import SignupPage from './components/SignupPage';
import ForgotPassword from './components/ForgotPassword';
import { Route, Routes, Outlet, Navigate } from "react-router-dom";
import { useAuthSession } from './hooks/useAuthSession';
import Header from "./components/Header";
import { signOut } from './auth'
import Projects from "./routes/Projects"

export function RedirectIfLoggedIn({ children, session = {},
  redirect = '/' }) {
  const { user, waitingForSession } = session; // useAuthSession();
  if (user) {
    return <Navigate to={redirect} replace />;
  }
  return children;
}

export function ProtectedRoute({
  children,
  session = {},
  redirect = '/auth/login',
}) {
  const { user, waitingForSession } = session; // useAuthSession();

  console.log('Is init change:', waitingForSession);
  if (waitingForSession) {
    return (
      <progress className="progress w-56"></progress>
    );
  }

  console.log('Current Session: ', session);
  if (!user) {
    console.log(
      'Protected route, no auth, redirecting to sign in',
      user,
    );
    return <Navigate to={redirect} replace />;
  }

  return children;
}
const Layout = ({ children, session }) => {
  const { user } = session;
  const handleLogout = async () => {
    // Implement your logout logic here
    await signOut();
    console.log('Logged out');
  }; 

  return <Outlet />

  // return (
  //   <div className="min-h-screen flex flex-col">
  //     {user != null && <Header onLogout={handleLogout} />}
  //     <div className="drawer">
  //       <input id="header-drawer" type="checkbox" className="drawer-toggle" />
  //       <div className="drawer-content flex-grow">
  //         {/* Page content here */}
  //         <main>
  //           <Outlet />
  //         </main>
  //       </div>
  //       <div className="drawer-side">
  //         <label htmlFor="header-drawer" className="drawer-overlay"></label>
  //         <ul className="menu p-4 w-80 bg-base-100 text-base-content">
  //           {/* Sidebar content here */}
  //           <li>
  //             <a href="#">Sidebar Item 1</a>
  //           </li>
  //           <li>
  //             <a href="#">Sidebar Item 2</a>
  //           </li>
  //         </ul>
  //       </div>
  //     </div>
  //   </div>
  // );
};

const ProtectedProjects = ({session}) => {
return <ProtectedRoute session={session}>
  <Projects />
</ProtectedRoute>
}

const App = () => {
  const session = useAuthSession()
  console.log('App session:', session);

  // return <Layout />;
  return (
    <Routes>
       <Route index 
        path="/p/:projectId" 
            element={<ProtectedProjects session={session} />} />
       <Route  
        path="/p/:projectId/:chatId" 
            element={<ProtectedProjects session={session} />} />
        <Route index path="/"
            element={<ProtectedProjects session={session} />} />
      <Route path="/auth" element={<Layout session={session} />} >
      <Route index 
            element={<ProtectedProjects session={session} />} />
        <Route path="/auth/login" element={
          <RedirectIfLoggedIn session={session}>
            <LoginPage />
          </RedirectIfLoggedIn>
        } />
        <Route path="/auth/signup" element={
          <RedirectIfLoggedIn session={session}>
            <SignupPage />
          </RedirectIfLoggedIn>
        } />

        <Route path="/auth/forgot" element={
          <RedirectIfLoggedIn session={session}>
            <ForgotPassword />
          </RedirectIfLoggedIn>
        } />
      </Route>
    </Routes>
  );
};

export default App;
