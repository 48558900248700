import React, { useState, useEffect } from "react";
import { useAuthSession } from "../../hooks/useAuthSession";
import { fetchProjects } from "../../store/fetchDB";
import { createProject } from "../../store/writeDB";

import AddIcon from "@mui/icons-material/Add";
import { Grid, Typography, Button, Box } from "@mui/material";
import ProjectGrid from "./ProjectGrid";
import CreateProjectModal from "../CreateProjectModal";
import useCreateGridItem from "../../hooks/useCreateGridItem";
import { useNavigate } from "react-router-dom";

export default function HomeBase({}) {
  const { user } = useAuthSession();
  const [projects, setProjects] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetchProjects(user.id).then(({ data, error }) => {
      if (error) {
        console.error("Error fetching projects:", error);
      } else {
        console.log("Projects:", data);
        setProjects(data);
      }
    });
  }, [user.id]);

  const handleFinishDialog = async (projectTitle) => {
    // Your logic for creating a new project goes here
    const { data, error } = await createProject(user.id, projectTitle);
    if (error) {
      console.error("Error creating project:", error);
    } else {
      console.log("Project created:", data);
      const newProjects = [...projects, data];
      setProjects(newProjects);
      // find project name matching project id:
      const project = newProjects.find(
        (project) => project.name === projectTitle
      );
      navigate(`/p/${project.id}`, { state: { project } });

      //   // setProjects([...projects, data]);
      //   fetchProjects(user.id).then(({ data, error }) => {
      //     if (error) {
      //       console.error("Error fetching projects:", error);
      //     } else {
      //       console.log("Projects:", data);
      //       setProjects(data);

      //       // find project name matching project id:
      //       const project = data.find((project) => project.name === projectTitle);
      //       navigate(`/p/${project.id}`, { state: { project } });
      //     }
      //   });
    }
  };

  const {
    openNameModal,
    setOpenNameModal,
    dialogueText,
    dialogueFct,
    handleCreateItem,
  } = useCreateGridItem(handleFinishDialog);

  // ...

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      minHeight="100%"
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          alignItems: "center",
          marginBottom: (theme) => theme.spacing(2),
        }}
      >
        <Button
          variant="contained"
          color="primary"
          size="large"
          startIcon={<AddIcon />}
          onClick={() => {
            handleCreateItem("Space Name");
          }}
          sx={{
            marginTop: (theme) => theme.spacing(2),
            marginBottom: (theme) => theme.spacing(2),
          }}
        >
          Create New Space
        </Button>
      </Box>
      <ProjectGrid
        items={projects}
        buttonText="Open Chats"
        onButtonClick={(project) => {
          navigate(`/p/${project.id}`, { state: { project: project } });
          //   handleCreateItem(project);
          //   console.log("Project click:", project);
        }}
      />
      <CreateProjectModal
        openState={{ open: openNameModal, setOpen: setOpenNameModal }}
        dialogueText={dialogueText}
        onCreate={dialogueFct.fct}
      />
    </Box>
  );
}
