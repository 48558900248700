import {useState} from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';


export default function CreateProjectModal({ openState, onCreate, dialogueText }) {
    const [projectName, setProjectName] = useState("");
  
    const handleClose = () => {
      openState.setOpen(false);
    };
  
    const handleSubmit = () => {
      if (onCreate) {
        onCreate(projectName);
        setProjectName("");
        handleClose();
      }
    };
    return (
      <Dialog open={openState.open} onClose={handleClose}>
        <DialogTitle>{dialogueText}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter a name for your new project.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="dialogueName"
            label={dialogueText}
            type="text"
            fullWidth
            value={projectName}
            onKeyPress={(e) => {
                if (e.key === 'Enter' && !e.shiftKey) {
                  e.preventDefault();
                  handleSubmit();
                }
              }}
            onChange={(e) => setProjectName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit}>Create</Button>
        </DialogActions>
      </Dialog>
    );
  }
  