import React from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";

const ProjectGrid = ({ items, buttonText, onButtonClick }) => {
  console.log("ProjectGrid items:", items);
  return (
    <Grid container spacing={3}>
      {items.map((item) => (
        <Grid item xs={12} sm={6} md={4} key={item.id}>
          <Card>
            <CardHeader title={item.name} />
            <CardContent>
              <Typography variant="body2" color="textSecondary">
                {`${item.type} created at: ${new Date(
                  item.created_at
                ).toLocaleDateString()}`}
              </Typography>
            </CardContent>
            <CardActions>
              <Button size="small" onClick={() => onButtonClick(item)}>
                {buttonText}
              </Button>
            </CardActions>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default ProjectGrid;
