import { useState, useEffect, useRef } from "react";
// import ReactQuill from 'react-quill';
import Prism from "./prism-imports";
// import ReactMarkdown from "react-markdown";
// import rehypeRaw from "rehype-raw";
import { useTheme } from "@mui/material/styles";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
// import { Editor, EditorProvider, HtmlEditor } from "@aeaton/react-prosemirror";
// import { plugins, schema } from "@aeaton/react-prosemirror-config-default";
import ChatMessage from "./ProseMessage";
import SendRoundedIcon from "@mui/icons-material/SendRounded";
import {
  // BrowserView, MobileView, isBrowser,
  isMobile,
} from "react-device-detect";
import {
  Avatar,
  Box,
  Typography,
  Paper,
  Button,
  TextField,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Input,
  Chip,
} from "@mui/material";
import useChatRealtime from "../hooks/useChatRealtime";
import { styled } from "@mui/material/styles";
import { useAuthSession } from "../hooks/useAuthSession";
import { supabase } from "../auth/supabaseClient";
import InviteUsersModal, {
  fetchChatParticipants,
} from "./groups/InviteUsersModal";
// import { useTheme } from "@mui/material/styles";
import { useNavigate, useParams } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import SettingsIcon from "@mui/icons-material/Settings";
import _ from "lodash";
import DragDrop from "./DragDrop";
import { addChatParticipant } from "../store/writeDB";
import { lightBlue } from "@mui/material/colors";
import { updateUserInfoData, useUserInfoData } from "../hooks/useQueryData";
const { Configuration, OpenAIApi } = require("openai");
const maxMessageFetch = 10;
const maxMessageHistory = 25;
const configuration = new Configuration({
  apiKey: process.env.REACT_APP_OPENAI_API_KEY,
});
const openai = new OpenAIApi(configuration);
console.log("AKEY:", process.env.REACT_APP_OPENAI_API_KEY);

const ChatBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  overflowY: "auto",
  // maxHeight: '70vh',
  padding: theme.spacing(1),
}));

const defaultUserAvatar = "/images/anakin.webp";
const defaultBotAvatar = "/images/aiAssistantMedium.png";

const UserInput = ({
  isTyping,
  whoIsTyping,
  onSubmit,
  onSettingsOpen,
  onInviteOpen,
  onIsUserTypingChanged,
}) => {
  const theme = useTheme();
  const [newMessage, setNewMessage] = useState("");
  const typingTimeout = 10000; // Timeout duration in milliseconds (e.g. 3000ms = 3 seconds)
  const [userIsTyping, setUserIsTyping] = useState(false);
  let typingTimer;

  useEffect(() => {
    if (userIsTyping) {
      clearTimeout(typingTimer);
      typingTimer = setTimeout(() => {
        setUserIsTyping(false);
      }, typingTimeout);
    }
  }, [userIsTyping]);

  useEffect(() => {
    // Notify the server when isTyping state changes
    if (onIsUserTypingChanged) {
      onIsUserTypingChanged(userIsTyping);
    }
  }, [userIsTyping]);

  const handleNewMessageChange = (event) => {
    const newText = event.target.value;
    setNewMessage(newText);

    if (newText.length > 0 && !userIsTyping) {
      console.log("typing raw event change: true");
      setUserIsTyping(true);
    } else if (newText.length === 0) {
      console.log("typing raw event change: false");
      setUserIsTyping(false);
    }
  };
  const handleSendMessage = () => {
    onSubmit(newMessage);
    setNewMessage("");
    setUserIsTyping(false);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        padding: !isMobile ? theme.spacing(3) : theme.spacing(1),
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          //  theme.spacing(2)

          gap: 1,
          visibility: isTyping ? "visible" : "hidden",
        }}
      >
        <CircularProgress size={15} />
        <Typography variant="body1">{whoIsTyping}</Typography>
      </Box>
      <Box sx={{ display: "flex", gap: isMobile ? 0.2 : 1 }}>
        <IconButton
          onClick={onInviteOpen}
          size={isMobile ? "sm" : "lg"}
          sx={{ p: isMobile ? 0.2 : 1 }}
        >
          <PersonAddAlt1Icon />
        </IconButton>
        <IconButton
          onClick={onSettingsOpen}
          size={isMobile ? "sm" : "lg"}
          sx={{ p: isMobile ? 0.2 : 1 }}
        >
          <SettingsIcon />
        </IconButton>
        <TextField
          variant="outlined"
          size="small"
          fullWidth
          multiline
          value={newMessage}
          onChange={handleNewMessageChange}
          onKeyPress={(e) => {
            if (e.key === "Enter" && !e.shiftKey) {
              e.preventDefault();
              handleSendMessage();
            }
          }}
          placeholder="Type Here..."
        />
        <Button
          onClick={handleSendMessage}
          variant="contained"
          color="primary"
          className="center"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            p: 0,
            minWidth: isMobile ? "48px" : "64px",
          }}
          endIcon={<SendRoundedIcon className="center" />}
        ></Button>
        {/* <Button variant="contained" onClick={handleSendMessage}> */}
        {/* Send */}
        {/* </Button> */}
      </Box>
    </Box>
  );
};

function Chat({}) {
  const { projectId, chatId } = useParams();

  const { user } = useAuthSession();
  const [messages, setMessages] = useState([]);

  const [whoIsTyping, setWhoIsTyping] = useState("");
  const [isTyping, setIsTyping] = useState(false);
  const bottomRef = useRef(null);
  const [systemContent, setSystemContent] = useState(
    "You are a very geeky Star Wars nerd, and you prickle at any comparison of star wars to star trek. You go out of your way to correct any inaccuracies in Star Wars canon. You also think JJ Abrams is a complete hack."
  );

  const [gptModel, setGptModel] = useState("gpt-3.5-turbo");
  const [settingsDialogOpen, setSettingsDialogOpen] = useState(false);

  const [isInviteModalOpen, setInviteModalOpen] = useState(false);

  // get our subscription to realtime
  const {
    changeState,
    addCallback: addMessageInsertCallback,
    addPresenceCallback,
  } = useChatRealtime({ chatId, userId: user.id });
  const userData = useUserInfoData();

  const handleInviteModalOpen = () => {
    setInviteModalOpen(true);
  };
  const handleSettingsDialogOpen = () => {
    setSettingsDialogOpen(true);
  };

  const handleSettingsDialogClose = () => {
    setSettingsDialogOpen(false);
  };

  useEffect(() => {
    addMessageInsertCallback("supachatOnNewMessage", onMessageUpdates);
  }, [addMessageInsertCallback]);

  useEffect(() => {
    addPresenceCallback("supachatOnNewState", onPresenceUpdates);
  }, [addPresenceCallback]);

  const onIsUserTypingChanged = (isUserTyping) => {
    if (isUserTyping) {
      console.log("User is typing...");
      changeState({ isTyping: true });
    } else {
      console.log("User stopped typing...");

      changeState({});
    }
  };

  const systemMessage = {
    role: "system",
    content: systemContent,
  };

  async function fetchMessages(projectId, chatId, maxMessages = null) {
    if (!projectId || !chatId) {
      console.error("Both projectId and chatId are required.");
      return;
    }

    let query = supabase
      .from("messages_with_users")
      .select(`*`)
      .eq("chat_id", chatId)
      .order("created_at", { ascending: false });

    if (maxMessages !== null) {
      query = query.limit(maxMessages);
    }

    const { data, error } = await query;

    if (error) {
      console.error("Error fetching messages:", error);
    } else {
      console.log("Messages:", data);

      // use lodash to sort by created_at
      // so latest messages are at the bottom
      const sortedData = _.orderBy(data, ["created_at"], ["asc"]);
      // then map to role, content
      const msgs = sortedData.map((msg) => {
        return {
          role: msg.metadata.role,
          content: msg.content,
          userId: msg.user_id,
          botId: msg.bot_id,
          user: {
            id: msg.user_id,
            username: msg.username,
            avatar_url: msg.avatar_url,
          },
        };
      });
      // get unique by user.id and ignore any nulls
      const users = _.filter(_.uniqBy(msgs, "user.id"), (u) => u.user.id);
      console.log("unique users", users);
      updateUserInfoData(_.map(users, "user"));

      console.log("Fetch messages:", msgs);
      setMessages(msgs);
      // Update your state or perform other actions with the fetched messages
    }
  }

  async function onMessageUpdates(payload) {
    console.log("Message Inserted:", payload.new);
    // if (payload.new) {
    // fetch messages
    fetchMessages(projectId, chatId, maxMessageFetch);
    // }
    // console.log("Message Inserted:", payload.new);
  }
  useEffect(() => {
    console.log("NEW USER DATA:", userData);
  }, [userData]);
  async function onPresenceUpdates(payload) {
    // need to reset the typing state
    console.log("Presence Updates:", payload);
    // lets convert payload to JSON string
    const payloadString = JSON.stringify(payload);
    // then parse it
    const payloadJSON = JSON.parse(payloadString);
    console.log("Presence JSON:", payloadJSON);

    // then check if any other user is typing
    // convert from object to array with lodash
    // map key to {key, ...value}
    const payloadArray = _.map(payloadJSON, (value, key) => {
      // get last value as most recent
      const isTypingAny = _.some(value, "isTyping");
      console.log("IS TYPING ANY:", isTypingAny, value, key, "");
      const lval = _.last(value);
      return { key, ...lval, isTyping: isTypingAny };
    });
    console.log("Presence Array:", payloadArray);
    const otherUsers = _.filter(payloadArray, (presenceUser) => {
      console.log(
        "Presence User:",
        presenceUser.isTyping,
        user.id !== presenceUser.key
      );
      return presenceUser.key !== user.id && presenceUser.isTyping;
    });

    console.log("Other Users:", otherUsers);
    // get all who are typing names as
    // {user1.botname}, {user2.botname}, and {user3.botname} are typing...
    // or if single user {user1.botname} is typing...
    const otherUsersTyping = otherUsers.length > 0;

    if (!otherUsersTyping) {
      setWhoIsTyping("");
      setIsTyping(false);
    } else {
      console.log(
        "OTHER USERS AND USER DATA Typing:",
        otherUsersTyping,
        userData
      );
      const otherUsersTypingNames = _.join(
        otherUsers.map((oUser) => {
          console.log(
            "USER CHK:",
            oUser,
            "current user:",
            user.id,
            "udata",
            userData,
            "key",
            oUser.key,
            "udkey",
            userData[oUser.key]
          );
          return (
            oUser.botname ||
            (userData[oUser.key] ? userData[oUser.key].username : "")
          );
        }),
        ", "
      );
      setIsTyping(true);
      setWhoIsTyping(
        `${otherUsersTypingNames} ${
          otherUsers.length > 1 ? "are" : "is"
        } typing...`
      );
    }
  }

  async function createMessage(chatId, userId, content, metadata) {
    const { data, error } = await supabase.from("messages").insert([
      {
        chat_id: chatId,
        user_id: userId,
        content: content,
        metadata: metadata,
      },
    ]);

    if (error) {
      console.error("Error creating message:", error);
    } else {
      console.log("Write to messages successful. Data:", data);
      // Update the messages state by fetching the latest messages
      fetchMessages(projectId, chatId, maxMessageFetch);
    }
  }

  useEffect(() => {
    if (projectId && chatId) {
      const fetchData = async () => {
        await fetchMessages(projectId, chatId, maxMessageFetch);
        // (data) => setMessages(data));
      };
      fetchData();
    }
  }, [projectId, chatId]);

  useEffect(() => {
    if (bottomRef.current) {
      bottomRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  const handleSendMessage = async (newMessage) => {
    const newMessageObj = {
      role: "user",
      content: newMessage,
    };
    const newMessages = [...messages, newMessageObj];
    // setMessages(newMessages);

    // create the message as ourselves
    await createMessage(chatId, user.id, newMessage, { role: "user" });
    setIsTyping(true);
    // now we're ready to send request to chat GPT

    // in the future, we'll have a webhook that will be
    // called when the message is created, and we'll
    // send the message to chat GPT from the secured server
    // this is not for client usage for obvious reasons

    console.log("New Messages:", newMessages);
    try {
      console.log("New Messages:", newMessages);
      console.log("sending completion");

      // // slice maxMessageHistory
      // const gptHistory = newMessages.slice(-maxMessageHistory);

      // const completion = await openai.createChatCompletion({
      //   model: gptModel,
      //   // model: "gpt-4",
      //   messages: [systemMessage, ...gptHistory],
      // });
      // const newGPTMessage = completion.data.choices[0].message;
      // console.log("Completion:", completion);
      // await createMessage(chatId, user.id, newGPTMessage.content, {
      //   role: newGPTMessage.role,
      //   model: gptModel,
      // });
      // setMessages([...newMessages, newGPTMessage]);
      setIsTyping(false);
    } catch (err) {
      console.log("error new message", err);
      setIsTyping(false);
    }
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        // gap: 2,
        height: "100%",
        overflowY: "auto",
      }}
    >
      {/* <DragDrop /> */}

      <ChatBox
        className="chat-box"
        sx={{
          flexGrow: 1,
          overflowY: "auto",
          p: 0,
        }}
      >
        {messages.map((message) => {
          // console.log(message);
          // const isUserMessage = !(message.role === "assistant");
          const isUserMessage =
            user.id === message.userId && !(message.role === "assistant");
          const isBotUser =
            message.botId !== null || message.role === "assistant";
          const messageUsername = isBotUser ? null : message.user.username;

          // console.log("Username:", messageUsername);
          const avatar_src = isBotUser
            ? defaultBotAvatar
            : isUserMessage
            ? defaultUserAvatar
            : message.user.avatar_url;

          const avatar_string =
            messageUsername === null ? "" : messageUsername[0].toUpperCase();

          // user.avatar_url || isUserMessage || message.user.username === null
          //   ? ""
          //   : message.user.username[0];
          // console.log("Message:", message);
          // message.user_id === user.id;

          return (
            <Box
              key={message.id}
              className="chat-wrap"
              sx={{
                m: 1,
                display: "flex",
                // width: "100%",
                flexDirection: isUserMessage ? "row-reverse" : "row",
                alignItems: "flex-end",
                gap: 1,
              }}
            >
              <Avatar
                src={avatar_src}
                sx={{
                  bgcolor: lightBlue[500],
                  width: isMobile ? 32 : 40,
                  height: isMobile ? 32 : 40,
                }}
              >
                {avatar_string}
              </Avatar>
              <Paper
                sx={{
                  // flexGrow: 1,
                  borderRadius: "20px",
                  padding: "10px",
                  background: isUserMessage ? "#0b93f6" : "#e5e5ea",
                }}
              >
                <ChatMessage message={message} userId={user.id} />
                {/* <Typography
                variant="body1"
                sx={{ color: isUserMessage ? '#ffffff' : '#000000' }}
              >
                {message.content}
              </Typography> */}
              </Paper>
            </Box>
          );
        })}
        <div ref={bottomRef} />
      </ChatBox>

      <UserInput
        isTyping={isTyping}
        whoIsTyping={whoIsTyping}
        onSubmit={handleSendMessage}
        onSettingsOpen={handleSettingsDialogOpen}
        onInviteOpen={handleInviteModalOpen}
        onIsUserTypingChanged={onIsUserTypingChanged}
      />
      <InviteUsersModal
        isOpen={isInviteModalOpen}
        handleClose={() => setInviteModalOpen(false)}
        identifierId={chatId}
        fetchFunction={fetchChatParticipants}
        onSubmit={(newParticipants) => {
          console.log("New Participants:", newParticipants);
          newParticipants.forEach((participant) => {
            console.log("Add participant:", participant);
            addChatParticipant(chatId, participant.id);
          });
          setInviteModalOpen(false);
        }}
      />
      <Dialog
        open={settingsDialogOpen}
        onClose={handleSettingsDialogClose}
        PaperProps={{
          style: {
            minHeight: "80%", // Adjust this value to set the desired height
          },
        }}
        fullWidth
        maxWidth="sm" // Change to "md" or "lg" for larger dialogs
      >
        <DialogTitle>Settings</DialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: 2,
          }}
        >
          <FormControl sx={{}} fullWidth minWidth={240}>
            <InputLabel>GPT Model</InputLabel>
            <Select
              value={gptModel}
              onChange={(e) => setGptModel(e.target.value)}
            >
              <MenuItem value="gpt-3.5-turbo">gpt-3.5-turbo</MenuItem>
              <MenuItem value="gpt-4">gpt-4</MenuItem>
            </Select>
            <TextField
              label="System Message"
              multiline
              value={systemContent}
              onChange={(e) => setSystemContent(e.target.value)}
              fullWidth
              margin="normal"
              variant="outlined"
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSettingsDialogClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default Chat;
