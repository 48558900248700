import { proxy, useSnapshot } from "valtio";
import supabase from "../auth/supabaseClient";

export const chatStateData = proxy({
  data: {},
});

export const projectStateData = proxy({
  data: {},
});

export const messageStateData = proxy({
  data: {},
});

export const userInfoStateData = proxy({
  data: {},
});
export const useChatData = () => {
  // console.log("Session state;", sessionState)
  const snapshot = useSnapshot(chatStateData);
  return snapshot.data;
};

export const updateChatData = (chats) => {
  chats.forEach((chat) => {
    chatStateData.data[chat.id] = chat;
  });
};

export const useProjectData = () => {
  // console.log("Session state;", sessionState)
  const snapshot = useSnapshot(projectStateData);
  return snapshot.data;
};

export const updateProjectData = (projects) => {
  projects.forEach((proj) => {
    projectStateData.data[proj.id] = proj;
  });
};

export const useMessageData = () => {
  // console.log("Session state;", sessionState)
  const snapshot = useSnapshot(messageStateData);
  return snapshot.data;
};

export const updateMessageData = (messages) => {
  messages.forEach((msg) => {
    messageStateData.data[msg.id] = msg;
  });
};
export const useUserInfoData = () => {
  // console.log("Session state;", sessionState)
  const snapshot = useSnapshot(userInfoStateData);
  return snapshot.data;
};

export const updateUserInfoData = (users) => {
  const umap = {};
  users.forEach((usr) => {
    umap[usr.id] = usr;
  });
  userInfoStateData.data = { ...userInfoStateData.data, ...umap };
  console.log("Updating user info data", umap, userInfoStateData);
};
