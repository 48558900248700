import { supabase } from "../auth/supabaseClient";
export async function createProject(userId, projectName) {
  // Add a project name as needed
  return await supabase
    .from("projects")
    .insert([{ name: projectName, owner_id: userId }])
    .select();
}

export async function createChat(userId, projectId, chatName) {
  return await supabase.from("chats").insert([
    {
      project_id: projectId,
      context: { name: chatName },
      created_by: userId,
    },
  ]);
}

export const addChatParticipant = async (chatId, userId) => {
  const { data, error } = await supabase
    .from("chat_participants")
    .insert([{ chat_id: chatId, user_id: userId }]);
  if (error) {
    console.error(error);
  } else {
    console.log(`User ${userId} has been added to chat ${chatId}`);
  }
};

export const addProjectMember = async (projectId, userId) => {
  const { data, error } = await supabase
    .from("project_members")
    .insert([{ project_id: projectId, user_id: userId }]);
  if (error) {
    console.error(error);
  } else {
    console.log(`User ${userId} has been added to project ${projectId}`);
  }
};
