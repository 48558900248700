import { useAuthSession } from "../hooks/useAuthSession";
import { supabase } from "../auth/supabaseClient";
import { useTheme } from "@mui/material/styles";
import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import AppHeader from "../components/AppHeader";
import SupaChat from "../components/SupaChat";
// import HomeBase from "../components/HomeBase";
import HomeBase from "../components/projects/HomeBase";
// import ArtifactBase from "../components/ArtifactBase";
import SpaceBase from "../components/projects/SpaceBase";
import _ from "lodash";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import { updateChatData, updateProjectData } from "../hooks/useQueryData";
import CreateProjectModal from "../components/CreateProjectModal";
import {
  Box,
  CssBaseline,
  Drawer,
  AppBar,
  Collapse,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  IconButton,
  Typography,
  Toolbar,
  Container,
  Grid,
  Paper,
  Link,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import MenuIcon from "@mui/icons-material/Menu";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import { Fragment } from "react";
import AddIcon from "@mui/icons-material/Add";
import { styled } from "@mui/material/styles";

const drawerWidth = 240;

const Main = styled(Box)(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(1),
  marginLeft: open ? drawerWidth : 0,
  // height: "100%",
  width: `calc(100% - ${open ? drawerWidth : 0}px)`,
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  //   padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  //   ...theme.mixins.toolbar,
}));

export default function Projects() {
  const theme = useTheme();
  const { projectId, chatId } = useParams();

  const [open, setOpen] = useState(false);
  const [openNameModal, setOpenNameModal] = useState(false);

  const [dialogueText, setDialogueText] = useState("");
  const [dialogueFct, setDialogueFct] = useState({ fct: null });
  const [projects, setProjects] = useState([]);
  const [chats, setChats] = useState([]);
  const { user } = useAuthSession();

  const navigate = useNavigate();

  useEffect(() => {
    fetchProjects();
  }, [user]);

  useEffect(() => {
    if (projectId) fetchChats(projectId);
  }, [projectId]);

  async function fetchProjects() {
    // console.log("Fetching projects for user:", user);
    if (user.id) {
      const { data, error } = await supabase.from("user_projects").select("*");
      // .eq("owner_id", user.id);

      if (error) {
        console.error("Error fetching projects:", error);
      } else {
        console.log("Projects:", data);
        // newest at the top for projects!
        const sortedProjects = _.sortBy(data, "created_at").reverse();
        updateProjectData(sortedProjects);
        setProjects(sortedProjects);
      }
    }
  }

  async function fetchChats(projectId) {
    console.log("Fetching chats for project:", projectId);
    if (projectId) {
      const { data, error } = await supabase
        .from("chats")
        .select()
        .eq("project_id", projectId);

      if (error) {
        console.error("Error fetching chats:", error);
      } else {
        console.log("Chats:", data);
        const sortedChats = _.sortBy(data, "created_at").reverse();
        updateChatData(sortedChats);
        setChats(sortedChats);
      }
    }
  }

  async function createProject(projectName) {
    // Add a project name as needed

    const { data, error } = await supabase
      .from("projects")
      .insert([{ name: projectName, owner_id: user.id }]);

    if (error) {
      console.error("Error creating project:", error);
    } else {
      console.log("Write to projects successful. Data:", data);
      //   setProjects([...projects, data[0]]);
      fetchProjects();
    }
  }
  async function createChat(userId, projectId, chatName) {
    const { data, error } = await supabase.from("chats").insert([
      {
        project_id: projectId,
        context: { name: chatName },
        created_by: userId,
      },
    ]);

    if (error) {
      console.error("Error creating chat:", error);
    } else {
      console.log("Write to chats successful. Data:", data);
      // Update the chats state by fetching the latest chats
      fetchChats(projectId);
    }
  }

  function handleProjectClick(newProjectId) {
    if (newProjectId === projectId) {
      navigate(`/p/${projectId}`);
      setOpen(false);

      //
      return;
    }

    navigate(`/p/${newProjectId}`);
    setChats([]);
    // setOpen(false);
  }

  function handleChatClick(projectId, chatId) {
    navigate(`/p/${projectId}/${chatId}`);
    // close the drawer
    setOpen(false);
  }

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box
      sx={{
        display: "flex",
        height: "100vh",
        overflow: "hidden",
        flexDirection: "column",
      }}
    >
      <CssBaseline />
      <AppHeader open={open} handleDrawerOpen={handleDrawerOpen} />

      <Drawer
        // Drawer styles
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {projects.map((project) => {
            // console.log("Space:", project);
            const isActiveProject = projectId === project.id;
            return (
              <Fragment key={project.id}>
                <ListItem disablePadding>
                  <ListItemButton
                    onClick={() => {
                      setDialogueText("Space Name");
                      setDialogueFct({ fct: createProject });
                      handleProjectClick(project.id);
                    }}
                    sx={{
                      backgroundColor: isActiveProject
                        ? "rgba(0, 0, 0, 0.08)"
                        : "transparent",
                    }}
                  >
                    <ListItemText primary={project.name} />
                  </ListItemButton>
                </ListItem>
                {isActiveProject &&
                  chats.length > 0 &&
                  chats.slice(0, 5).map((chat) => {
                    const isActiveChat = chatId === chat.id;

                    return (
                      <List key={chat.id} component="div" disablePadding>
                        <ListItemButton
                          sx={{
                            backgroundColor: isActiveChat
                              ? "rgba(0, 0, 0, 0.08)"
                              : "transparent",
                          }}
                          onClick={() => handleChatClick(project.id, chat.id)}
                        >
                          <ListItemText primary={chat.context.name} inset />
                        </ListItemButton>
                      </List>
                    );
                  })}
                {isActiveProject && chats.length > 5 && (
                  <ListItem disablePadding>
                    <ListItemButton
                      onClick={() => {
                        navigate(`/p/${projectId}`);
                        setOpen(false);
                      }}
                    >
                      <ListItemIcon>
                        <FolderOpenIcon />
                      </ListItemIcon>
                      <ListItemText primary="See all chats" />
                    </ListItemButton>
                  </ListItem>
                )}

                {isActiveProject && (
                  // create new chat button
                  <ListItem disablePadding>
                    <ListItemButton
                      onClick={() => {
                        setDialogueText("Chat Name");
                        setDialogueFct({
                          fct: (chatName) => {
                            console.log(
                              "Attempting to create chat with name:",
                              chatName,
                              "for project:",
                              project.id
                            );
                            return createChat(user.id, project.id, chatName);
                          },
                        });
                        setOpenNameModal(true);
                      }}
                    >
                      <ListItemIcon>
                        <AddIcon />
                      </ListItemIcon>
                      <ListItemText primary="New Chat" />
                    </ListItemButton>
                  </ListItem>
                )}
              </Fragment>
            );
          })}
        </List>
        <Divider />
        <ListItem>
          <ListItemButton
            onClick={() => {
              setDialogueText("Space Name");
              setDialogueFct({ fct: createProject });
              setOpenNameModal(true);
            }}
          >
            <ListItemIcon>
              <AddIcon />
            </ListItemIcon>
            <ListItemText primary={"Create Space"} />
          </ListItemButton>
        </ListItem>
      </Drawer>
      <Main
        open={open}
        sx={{
          overflowY: "auto",
          // overflow: "auto",
        }}
      >
        <DrawerHeader />
        <CreateProjectModal
          openState={{ open: openNameModal, setOpen: setOpenNameModal }}
          dialogueText={dialogueText}
          onCreate={dialogueFct.fct}
        />
        {projectId && chatId && <SupaChat />}
        {!projectId && !chatId && <HomeBase />}
        {projectId && !chatId && <SpaceBase />}
        {/* Main content */}
      </Main>
    </Box>
  );
}
