import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { styled } from "@mui/material/styles";

const DropZone = styled(Box)(({ theme }) => ({
  border: "2px dashed",
  borderColor: theme.palette.secondary.main,
  borderRadius: theme.spacing(1),
  textAlign: "center",
  padding: theme.spacing(2),
  cursor: "pointer",
}));

const UploadIcon = styled(CloudUploadIcon)(({ theme }) => ({
  fontSize: theme.spacing(6),
}));

const DragDrop = () => {
  const onDrop = useCallback((acceptedFiles) => {
    // Do something with the files, e.g., upload them to the server
    acceptedFiles.forEach((file) => {
      console.log("Accepted file:", file);
    });
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <DropZone {...getRootProps()}>
      <input {...getInputProps()} />
      <UploadIcon />
      <Typography>
        {isDragActive
          ? "Drop the files here..."
          : "Drag and drop files here, or click to select"}
      </Typography>
    </DropZone>
  );
};

export default DragDrop;
