import { useState } from 'react';

const useCreateGridItem = (handleCreate) => {
  const [openNameModal, setOpenNameModal] = useState(false);
  const [dialogueText, setDialogueText] = useState('');
  const [dialogueFct, setDialogueFct] = useState({ fct: null });

  const handleCreateItem = async (itemName) => {
    setOpenNameModal(true);
    setDialogueText(itemName);
    setDialogueFct({ fct: handleCreate });
  };

  return {
    openNameModal,
    setOpenNameModal,
    dialogueText,
    dialogueFct,
    handleCreateItem,
  };
};
export default useCreateGridItem;
